import axiosInstance from "./api";
import { environment } from "./config";

export default {
  createAccount,
  validateToken,
  getUser,
  getEmailByToken,
  updatePreference,
  uploadBanner,
  updateBasicInfoContactCenter,
  createAbilities,
  createUsers,
  validateTokenStatus,
  validateAccount,
  getContactCenterURL,
  createMembership,
  updateMembership,
};

function getContactCenterURL(companyId) {
  return axiosInstance.get(`/easy/company/contact_center/app/${companyId}`);
}

function createAccount(payload) {
  return axiosInstance.post(`/easy/company/add`, payload);
}

function validateToken(payload) {
  return axiosInstance.put(`/easy/token/validate`, payload);
}

function validateTokenStatus(token) {
  return axiosInstance.get(`/easy/token/status/${token}`);
}

function getUser(email) {
  return axiosInstance.get(`easy/account/${email}`);
}

function getEmailByToken(token) {
  return axiosInstance.get(`easy/token/email/${token}`);
}

function updatePreference(payload) {
  return axiosInstance.put(`easy/preferences`, payload);
}

function uploadBanner(file, companyId) {
  const formData = new FormData();
  formData.append("image", file);
  formData.append("companyId", companyId);
  return axiosInstance.post(`${environment.storage}/easy/banner`, formData);
}

function updateBasicInfoContactCenter(payload) {
  return axiosInstance.put(`easy/preferences`, payload);
}

function createAbilities(payload) {
  return axiosInstance.post(`easy/abillities`, payload);
}

function createUsers(payload) {
  return axiosInstance.post(`easy/users`, payload);
}

function validateAccount(payload) {
  return axiosInstance.post(
    `easy/account/confirmation/changePassword`,
    payload
  );
}

function createMembership(company) {
  return axiosInstance.post(`memberships`, { company });
}

function updateMembership(payload) {
  console.log(payload);
  return axiosInstance.put(`memberships`, payload);
}
