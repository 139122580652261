export const isProd = process.env.NODE_ENV === "production";
export const REACT_URL = window.location.host;
export const TOKEN_KEY = `user_token`;

export function getEnvironment() {
  let env = "development";
  const customEnv = process.env.REACT_APP_ENV || "staging";
  switch (customEnv) {
    case "production":
      env = "production";
      break;
    case "qa":
      env = "qa";
      break;
    case "staging":
      env = "staging";
      break;
    case "dev":
      env = "dev";
      break;
    default:
      env = process.env.NODE_ENV;
      break;
  }
  return env;
}

export const environment =
  getEnvironment() === "production"
    ? {
        storage: "https://cdn.videolink.app",
        server: "https://demoserverapi.videolink.app",
        agentURL: "https://agent.videolink.app",
        adminURL: "https://admin.videolink.app",
        defaultBanner: "https://cdn.videolink.app/c/63a08f2a190bc20039b960bc",
      }
    : getEnvironment() === "qa"
    ? {
        storage: "https://cdn.dev.videolink.app",
        server: "https://api.qa.videolink.app",
        agentURL: "https://agent.qa.videolink.app",
        adminURL: "https://adminv2.qa.videolink.app",
        defaultBanner: "https://cdn.videolink.app/c/63a08f2a190bc20039b960bc",
      }
    : getEnvironment() === "staging"
    ? {
        storage: "https://cdn.dev.videolink.app",
        server: "https://api.pre.videolink.app",
        agentURL: "https://agent.pre.videolink.app",
        adminURL: "https://admin.pre.videolink.app",
        defaultBanner: "https://cdn.videolink.app/c/63a08f2a190bc20039b960bc",
      }
    : getEnvironment() === "dev" || getEnvironment() === "development"
    ? {
        storage: "https://cdn.dev.videolink.app",
        server: "https://api.dev.videolink.app",
        agentURL: "https://agent.dev.videolink.app",
        adminURL: "https://adminv2.dev.videolink.app",
        defaultBanner: "https://cdn.videolink.app/c/63a08f2a190bc20039b960bc",
      }
    : {
        storage: "https://cdn.dev.videolink.app",
        server: "http://localhost:3003",
        agentURL: "https://agent.qa.videolink.app",
        adminURL: "https://adminv2.qa.videolink.app",
        defaultBanner: "https://cdn.videolink.app/c/63a08f2a190bc20039b960bc",
      };

function getServerHost() {
  const apiUrl = environment.server;

  if (apiUrl) {
    return apiUrl;
  } else {
    return isProd
      ? `http://${window.location.host}`
      : `http://${window.location.hostname}:3003`;
  }
}

//URL
export const SERVER_HOST = getServerHost();

export const API_BASE_URL = `${SERVER_HOST}`;
